<template>
  <div>
    <router-view v-if="maintenance === 0 && isLoaded == true" style="" id="main-core" />
    <img v-if="maintenance === 1 && isLoaded == true" id="update-img" src="./assets/includes/images/update_bg.jpg" alt=""
      style="max-width: 100%; max-height: 100%; position: fixed; bottom: 0;" />
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      maintenance: 1,
      isLoaded: false,
      development: false,
    };
  },
  mounted() {

    const currentURL = window.location.href;
    if (currentURL.endsWith('8080') || currentURL.includes(':8080') || currentURL.includes('okyu76.demo6.betluxury-india.online')) {
      this.development = true;
    }

    axios
      .get("https://rjclub.live/jeetofiles/src/api/game_settings.php")
      .then((res) => {
        if (res.data.message == "Unauthorized") {
          localStorage.removeItem("username");
          localStorage.removeItem("token");
          this.$router.push("/login");
        }
        this.maintenance = parseInt(res.data[0]['maintenance']);
        this.isLoaded = true;
        if (this.development) {
          this.maintenance = 0;
        }
      })
      .catch((err) => {
        this.isLoaded = true;
        if (this.development) {
          this.maintenance = 0;
        }
        console.error("Error fetching maintenance status:", err);
      });
  }
};
</script>

<style>
@import '@/assets/index.css';
</style>
